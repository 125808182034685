import {
  ChatHistory,
  ChatHistoryByOrgMgResponse,
  ChatHistoryByOrgResponse,
  ChatHistoryMg,
} from '@dialog/search-contracts';

export const formatChatHistoriesFromMgToLegacy = (
  chatHistories: ChatHistoryByOrgMgResponse,
): ChatHistoryByOrgResponse => {
  return {
    next: chatHistories.next,
    items: chatHistories.items?.map(item =>
      formatChatHistoryFromMgToLegacy(item),
    ),
  };
};

export const formatChatHistoryFromMgToLegacy = (
  chatHistory: ChatHistoryMg,
): ChatHistory => {
  return {
    userId: chatHistory.user_id,
    sessionId: chatHistory.session_id,
    organizationSlug: chatHistory.organization_slug,
    created: chatHistory.updated_at,
    updated: chatHistory.updated_at,
    messages: chatHistory.messages.map(message => ({
      ...message,
      created: chatHistory.updated_at,
    })),
  };
};
