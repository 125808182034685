export const getBase64FromFile = (
  file: File,
): Promise<{
  readableByApi: string | undefined;
  readableByImg: string | undefined;
}> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      const result = fileReader.result;

      const readableByApi = (result as string).split(',').pop();
      resolve({ readableByApi, readableByImg: result as string });
    };
    fileReader.onerror = () => {
      reject(new Error('Error reading file'));
    };
  });
};

export const handleFileRead = async (
  event: React.SyntheticEvent,
): Promise<
  | {
      base64: string | undefined;
      file: File;
      readableByImg: string | undefined;
    }
  | undefined
> => {
  const target = event.target as HTMLInputElement;
  if (target.files !== null) {
    const file = target.files[0];
    if (file) {
      return readFile(file);
    }
  }

  return undefined;
};

export const readFile = async (
  file: File,
): Promise<
  | {
      base64: string | undefined;
      file: File;
      readableByImg: string | undefined;
    }
  | undefined
> => {
  try {
    const { readableByApi, readableByImg } = await getBase64FromFile(file);

    return {
      file,
      base64: readableByApi,
      readableByImg,
    };
  } catch (error) {
    console.error('Error reading file', error);

    return undefined;
  }
};

export const FILE_SIZE_LIMIT = 3145728;
export const isUnderFileLimitSize = (size?: number): boolean =>
  size !== undefined && size <= FILE_SIZE_LIMIT;
export const DOCUMENT_SIZE_LIMIT = 50000000;
export const isUnderDocumentLimitSize = (size?: number): boolean =>
  size !== undefined && size <= DOCUMENT_SIZE_LIMIT;
